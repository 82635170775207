import store from '@/state/store.js';
export default {
  data() {
    return {
      buttonLoading: false,
      dialogVisible: false,
      specificPayment: {},
      page: 1,
      pageSize: 20,
      total: 0
    };
  },
  computed: {
    tableData() {
      return store.getters['admin/reporting/specificDebtor'].debtors_details;
    }
  },
  methods: {
    openModal({
      row
    }) {
      this.dialogVisible = true;
      this.specificPayment = row;
    },
    confirmDeletionMethod() {
      this.buttonLoading = true;
      store.dispatch('admin/reporting/deleteSpecificDebtor', {
        id: this.$route.params.id,
        payment_id: this.specificPayment.payment_id
      }).then(() => {
        this.buttonLoading = false;
        this.dialogVisible = false;
        // Refetch data
        store.dispatch('admin/reporting/getSpecificDebtor', this.$route.params.id);
      });
    },
    handleCurrentChange(val) {
      this.page = val;
    }
  },
  beforeMount() {
    store.dispatch('admin/reporting/getSpecificDebtor', this.$route.params.id);
  }
};